* {
  margin: 0;
  padding: 0;
}

*,
*:before,
*:after {
  box-sizing: border-box;
  scroll-behavior: smooth;
}

html {
  position: fixed;
  font-size: 16px;
  background-color: #f7f4e8;
  overflow: hidden;
}

body,
#root {
  color: #2f3436;
  font-size: 1rem;
  font-weight: 400;
  font-family: Arial, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans',
    'Helvetica Neue', sans-serif;
  background-color: #f7f4e8;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: transparent;
}

a {
  box-shadow: none !important;
}

@font-face {
  font-family: 'OctinCollegeRegular';
  src: url('./assets/fonts/OctinCollege-Regular.otf') format('truetype');
}

@font-face {
  font-family: 'OctinCollegeBold';
  src: url('./assets/fonts/OctinCollege-Bold.otf') format('truetype');
}

@font-face {
  font-family: 'PoppinsRegular';
  src: url('./assets/fonts/Poppins-Regular.ttf') format('truetype');
}
